import { helmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";
import React from "react";
import { useLocation } from "react-router-dom";
import { Routes } from "../../Constants/Routes";

interface Props {
  winePaketName: string;
  winePaketVideos: Array<{ name: string; url: string }>;
}

export default function ReadyToTaste(props: Props) {
  const location = useLocation();
  const helmetProperties = helmetPropertiesFor(
    "Winzerliebe „Mission Lieblingswein“",
    location.pathname,
  );
  return (
    <div>
      <HelmetWrapper helmetProperties={helmetProperties} />
      <section className="my-5">
        <div className="container">
          <h1>
            Gute Wahl! Du hast dich für Weinpaket "{props.winePaketName}"
            entschieden!
          </h1>
          <p>
            Bist du bereit, die Story hinter den Weinen aus eurem
            Paketherauszufinden und deinen Lieblingswein zu finden?
          </p>
          <h3>Let‘s get ready to taste it!</h3>
          <p>So geht's</p>
          <ol>
            <li>
              ihr wählt aus, wie ihr spielen wollt: entweder spielt ihr die
              Fragen per Podcast / Video oder klassisch mit dem beiliegenden
              Spielplan pro Person.
            </li>
            <li>
              Bereitet die Weinprobe vor – wie das geht, steht auf dem
              beiliegenden Spielplan.
            </li>
            <li>
              Jeder Spieler beantwortet eigenständig seine Fragen – am Ende
              vergleicht ihr eure Antworten mit den Lösungen und wertet euer
              Spielergebnis aus – „and the Winner is....“ tata! Legt vorher
              fest, was der Gewinner bekommt: vielleicht darf sie/er das nächste
              Weinprobenpaket für eure lustigeRunde bestellen und somit den
              nächsten geilen Abend planen?
            </li>
            <li>
              vergesst nicht, euren Lieblingswein aufzuschreiben. Auf dem
              beiliegenden Spielplan ist dafür ein Extra-Feldvorgesehen, samt
              QR-Link, damit ihr direkteuren Wein nachbestellen könnt.
            </li>
          </ol>
        </div>
      </section>
      {props.winePaketVideos.length !== 0 ? (
        <section className="mb-5">
          <div className="container">
            <h2>
              Du willst mehr über deinen Wein erfahren? Kein Problem –Bühne
              frei!
            </h2>
            <p>Suche aus, was du wissen möchtest:</p>
            {props.winePaketVideos.map((video) => getVideo(video.url))}
          </div>
        </section>
      ) : (
        <></>
      )}
      <section className="mb-5">
        <div className="container">
          <a
            href={Routes.shop.home}
            className="btn btn-winzer-red col"
            rel="noopener noreferrer"
            target="_blank"
          >
            Zu den Weinen
          </a>
        </div>
      </section>
    </div>
  );

  function getVideo(url: string) {
    return (
      <iframe
        key={url}
        title={url}
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="col-md-4 mb-4"
      ></iframe>
    );
  }
}
