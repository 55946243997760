import React from "react";
import ImageWeinAnstoßenJpg from "../../assets/img/wein_anstoßen.jpg";
import ImageWeinAnstoßenWebp from "../../assets/img/wein_anstoßen.webp";
import AndreasHerzogJpg from "../../assets/img/about_us/andreas_herzog.jpg";
import AndreasHerzogWebp from "../../assets/img/about_us/andreas_herzog.webp";
import { useLocation } from "react-router";
import { helmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";

export default function AboutUs() {
  const location = useLocation();
  const helmetProperties = helmetPropertiesFor(
    "Winzerliebe „Mission Lieblingswein“",
    location.pathname,
  );
  return (
    <div>
      <HelmetWrapper helmetProperties={helmetProperties} />
      <section className="my-5">
        <div className="container">
          <h1>Über uns</h1>
          <section className="mb-5">
            <div className="container">
              <div className="row">
                <p>Hey, da sind wir! (Noch) klein aber fein: </p>
              </div>
            </div>
          </section>
          <section className="mb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 pr-md-5 mb-3 mb-md-0">
                  <picture>
                    <source srcSet={AndreasHerzogWebp} type="image/webp" />
                    <source srcSet={AndreasHerzogJpg} type="image/jpeg" />
                    <img
                      src={AndreasHerzogJpg}
                      className="img-fluid rounded shadow"
                      alt="Foto von Andreas Herzog"
                    />
                  </picture>
                </div>
                <div className="col-md-7 col-sm-12 pl-md-5 align-self-center">
                  <h2>Andreas Herzog</h2>
                  <p>
                    Gründer und Geschäftsführer.
                    <br />
                    Als Dozent an der Hochschule Geisenheim habe ich mich dem
                    Problem gestellt, dass Menschen gerne Wein trinken, aber die
                    Mehrheit keine Wissenschaft daraus machen will, sondern
                    einfach nur sagen möchte: „der schmeckt mir“– und Winzer
                    kommen schwer an neue Kunden heran, besonders überregional.
                    Dafür möchte ich mit WINZERLIEBE eine Lösung schaffen, die
                    euch hoffentlich gefällt.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-sm-12 pl-md-5 align-self-center">
                  <h2>Roman Engel</h2>
                  <p>
                    unser Tech-Nerd. Liebt Wein mindestens genauso wie Andreas –
                    aber weiß auch, wie man das im Internet zur Schau stellt.
                  </p>
                </div>
                <div className="col-md-5 col-sm-12 pr-md-5 mb-3 mb-md-0">
                  <picture>
                    <source srcSet={ImageWeinAnstoßenWebp} type="image/webp" />
                    <source srcSet={ImageWeinAnstoßenJpg} type="image/jpeg" />
                    <img
                      src={ImageWeinAnstoßenJpg}
                      className="img-fluid rounded shadow"
                      alt="Foto von Roman Engel"
                    />
                  </picture>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-5">
            <div className="container">
              <div className="row">
                <h2>Warum Winzerliebe?</h2>
                <p>
                  Wir haben hervorragende Winzer in Deutschland, aber vielen
                  fehlt der richtige Zugang zu neuen Kunden. Winzerliebe sucht
                  „Hidden Champion“-Weine von leidenschaftlichen Winzern und
                  bringt Winzer und Weine über den spielerischen Weg mit viel
                  Storytelling an neue Kunden heran.
                </p>
              </div>
            </div>
          </section>
          <section className="mb-5">
            <div className="container">
              <div className="row">
                <h2>Winzerliebe – was bedeutet dieser Name?</h2>
                <p>
                  In jedem Wein steckt hohe handwerkliche Winzerkunst und echte
                  Leidenschaft. Eben die Liebe des Winzers, um aus den Trauben
                  einen perfekten Wein zu machen. Winzerliebe hilft, durch viele
                  unerwartete tiefe Einblicke in Winzer, Weingut und Wein, eine
                  emotionale Verankerung beim Kunden herzustellen.
                </p>
              </div>
            </div>
          </section>
          <section className="mb-5">
            <div className="container">
              <div className="row">
                <h2>
                  Die Mission von Winzerliebe: „Mission Lieblingswein“ – was
                  heißt das?
                </h2>
                <p>
                  Jeder Mensch sollte einen Lieblingswein haben, der ihm
                  schmeckt. Wir brauchen kein akademisches Weinkenner-Gedöns,
                  sondern einfach einen Lieblingswein zumWohlfühlen, Entspannen
                  und Teilen. Diesen zu entdecken – das ist unsere Mission.
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
