import { helmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";
import React from "react";
import { useLocation } from "react-router-dom";

export default function HowItWorks() {
  const location = useLocation();
  const helmetProperties = helmetPropertiesFor(
    "Winzerliebe „Mission Lieblingswein“",
    location.pathname,
  );
  return (
    <div>
      <HelmetWrapper helmetProperties={helmetProperties} />
      <section className="my-5">
        <div className="container">
          <h1>So Geht's</h1>
          <section className="mb-5">
            <div className="container">
              <div className="row">
                <ol>
                  <li>
                    Finde das richtige Weinprobenpaket für dich und deine Leute.
                  </li>
                  <li>
                    Lade Freunde, Familie oder Kollegen zu einem phänomenalen
                    Abend ein
                  </li>
                  <li>
                    Mach die Weinverkostung mit deiner Crowd bei dir zu Hause,
                    am Strandoder im Park und löst die Rätsel zu den Weinen
                  </li>
                  <li>
                    Die Lösungen führen euch zur Story und zum richtigen Wein
                  </li>
                  <li>
                    „And the Winner is...“ – Einer von euch ist Weinpassionista
                    des Abends und darf das nächste Weinpaket für euren nächsten
                    Abend aussuchen
                  </li>
                </ol>
                <p>
                  <span className="text-winzer-red">ABER ACHTUNG:</span> die
                  Verkostung ist blind – d.h. die Etiketten sind verdeckt, damit
                  du nicht vom Etikett beeinflusst wirst. Kannst du die Story
                  dem richtigen Wein zuordnen? Du sollst rein nach Geruch, Farbe
                  und Geschmack den Wein beurteilen. Dadurch hast du ein viel
                  intensiveres Genusserlebnis und lernst nebenbei spielerisch
                  die Story zu jedem Wein kennen.
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
