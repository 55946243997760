import { helmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";
import React from "react";
import { useLocation } from "react-router-dom";
import WeinAnstoßenFrauenJpg from "../../assets/img/wein_anstoßen_frauen.jpg";
import WeinAnstoßenFrauenWebp from "../../assets/img/wein_anstoßen_frauen.webp";
import WeinEinschenkenJpg from "../../assets/img/wein_einschenken.jpg";
import WeinEinschenkenWebp from "../../assets/img/wein_einschenken.webp";
import { Routes } from "../../Constants/Routes";

export default function B2B() {
  const location = useLocation();
  const helmetProperties = helmetPropertiesFor(
    "Winzerliebe „Mission Lieblingswein“",
    location.pathname,
  );
  return (
    <div>
      <HelmetWrapper helmetProperties={helmetProperties} />
      <section className="my-5">
        <div className="container">
          <h1>B2B</h1>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12 pr-md-0 pb-md-0 pt-md-0 mb-sm-0 mb-3">
              <picture>
                <source srcSet={WeinAnstoßenFrauenWebp} type="image/webp" />
                <source srcSet={WeinAnstoßenFrauenJpg} type="image/jpeg" />
                <img
                  src={WeinAnstoßenFrauenJpg}
                  className="img-fluid rounded shadow"
                  alt="Wein Anstoßen"
                />
              </picture>
            </div>
            <div className="col pl-md-5 align-self-center">
              <h2>Winzer für Winzerliebe werden</h2>
              <p>
                Du bist selbst Winzer und denkst, dass dein Wein auch hier dabei
                sein sollte, weil er auch ein „hidden champion“ und einfach der
                Hammer ist und du als Winzer sowieso?
              </p>
              <p>
                <b>
                  Dann melde dich bei uns – zögere nicht, wir gehören scheinbar
                  zusammen
                </b>
                <ul className="list-unstyled">
                  <li>
                    <a href={Routes.shop.contact}>Kontraktanfrage</a>
                  </li>
                  <li>
                    oder per E-Mail:{" "}
                    <a href={Routes.mails.winzerliebe}>
                      {Routes.mails.winzerliebe.replace("mailto:", "")}
                    </a>
                  </li>
                  <li>
                    oder per WhatsApp:{" "}
                    <a href={Routes.phones.winzerliebe}>
                      {Routes.phones.winzerliebe.replace("tel:", "")}
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          <div className="row">
            <div className="col pr-md-5 align-self-center">
              <h2>Einzelhändler für Winzerliebe werden</h2>
              <p>
                Du bist Einzelhändler und möchtest die Weinprobepakete à la Ü-Ei
                mit Spiel, Spaß, Spannung und ganz viel leckerem Wein in dein
                Sortiment aufnehmen?
              </p>
              <p>
                <b>
                  Dann melde dich bei uns – zögere nicht, wir gehören scheinbar
                  zusammen
                </b>
                <ul className="list-unstyled">
                  <li>
                    <a href={Routes.shop.contact}>Kontraktanfrage</a>
                  </li>
                  <li>
                    oder per E-Mail:{" "}
                    <a href={Routes.mails.winzerliebe}>
                      {Routes.mails.winzerliebe.replace("mailto:", "")}
                    </a>
                  </li>
                  <li>
                    oder per WhatsApp:{" "}
                    <a href={Routes.phones.winzerliebe}>
                      {Routes.phones.winzerliebe.replace("tel:", "")}
                    </a>
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-5 pl-md-0 pb-md-0 pt-md-0">
              <picture>
                <source srcSet={WeinEinschenkenWebp} type="image/webp" />
                <source srcSet={WeinEinschenkenJpg} type="image/jpeg" />
                <img
                  src={WeinEinschenkenJpg}
                  className="img-fluid rounded shadow"
                  alt="Wein einschenken"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
