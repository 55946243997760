import React from "react";
import { Routes } from "../../Constants/Routes";
import logoPng from "../../assets/img/logo_weiss.png";
import logoWebp from "../../assets/img/logo_weiss.webp";
import { helmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";
import { useLocation } from "react-router-dom";

export default function Imprint() {
  const location = useLocation();
  const helmetProperties = helmetPropertiesFor(
    "Winzerliebe „Mission Lieblingswein“",
    location.pathname,
  );
  return (
    <div>
      <HelmetWrapper helmetProperties={helmetProperties} />
      <section className="my-5">
        <div className="container">
          <h1>Impressum</h1>
          <p>Angaben gemäß § 5 TMG</p>
        </div>
      </section>
      <section className="container mb-5">
        <picture>
          <source srcSet={logoWebp} type="image/webp" />
          <source srcSet={logoPng} type="image/png" />
          <img src={logoPng} alt="Winzerliebe Logo" className="logo" />
        </picture>
        <ul className="list-unstyled">
          <li>Langendellschlag 17a</li>
          <li>65199 Wiesbaden</li>
          <li>Ust Id.Nr: DE315067449</li>
        </ul>
        <b>Vertreten durch</b>
        <p>Andreas Herzog (Geschäftsführer)</p>
        <b>Kontakt</b>
        <ul className="list-unstyled">
          <li>
            <a href={Routes.phones.daubit}>
              {Routes.phones.winzerliebe.replace("tel:", "")}
            </a>
          </li>
          <li>
            <a href={Routes.mails.winzerliebe}>
              {Routes.mails.winzerliebe.replace("mailto:", "")}
            </a>
          </li>
        </ul>
      </section>
      <section className="container mb-5">
        <h2>Technische Umsetzung:</h2>
        <ul className="list-unstyled">
          <li>Daubit Programmierung Service GmbH</li>
          <li>Riederbergstraße 3</li>
          <li>65195 Wiesbaden</li>
          <li>Vertreten durch: Roman Engel</li>
        </ul>
        <b>Kontakt</b>
        <ul className="list-unstyled">
          <li>
            <a href={Routes.phones.daubit}>
              {Routes.phones.daubit.replace("tel:", "")}
            </a>
          </li>
          <li>
            <a href={Routes.fax.daubit}>
              {Routes.fax.daubit.replace("tel:", "")}
            </a>
          </li>
          <li>
            <a href={Routes.websites.daubit}>
              {Routes.websites.daubit.replace("https://", "")}
            </a>
          </li>
          <li>
            <a href={Routes.mails.daubit}>
              {Routes.mails.daubit.replace("mailto:", "")}
            </a>
          </li>
        </ul>
      </section>
      <section className="container mb-5">
        <h2>Haftungsausschluss</h2>
        <h3>EU-Streitschlichtung</h3>
        <p>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a href="https://ec.europa.eu/consumers/odr">
            https://ec.europa.eu/consumers/odr
          </a>
          . Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>
        <h3>Haftung für Inhalte</h3>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. 2 / 4 Verpflichtungen zur Entfernung oder
          Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
          bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
          ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
          möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
          werden wir diese Inhalte umgehend entfernen.
        </p>
        <h3>Haftung für Links</h3>
        <p>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <h3>Urheberrecht</h3>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Sollten Sie auf eine Urheberrechtsverletzung aufmerksam werden, bitten
          wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
      </section>
      <section className="container mb-5">
        <p>Quelle: eRecht24</p>
      </section>
    </div>
  );
}
