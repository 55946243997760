import { helmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";
import React from "react";
import { useLocation } from "react-router-dom";

export default function NoMatch() {
  const location = useLocation();
  const helmetProperties = helmetPropertiesFor(
    "Winzerliebe „Mission Lieblingswein“",
    location.pathname,
  );
  return (
    <div>
      <HelmetWrapper helmetProperties={helmetProperties} />
      <section className="my-5">
        <div className="container">
          <h1>404 - Seite nicht gefunden</h1>
        </div>
      </section>
    </div>
  );
}
