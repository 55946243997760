import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Routes } from "../../Constants/Routes";
import logoPng from "../../assets/img/logo.png";
import logoWebp from "../../assets/img/logo.webp";
import { Link } from "react-router-dom";

export default function NavigationBar() {
  return (
    <div className="header-spacer">
      <Navbar
        expand="lg"
        className="navbar navbar-light bg-white fixed-top border-bottom border-light"
      >
        <Navbar.Brand href={Routes.home}>
          <picture>
            <source srcSet={logoWebp} type="image/webp" />
            <source srcSet={logoPng} type="image/png" />
            <img src={logoPng} alt="Winzerliebe Logo" className="logo" />
          </picture>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Item>
              <Link to={Routes.home} className="nav-link">
                Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={Routes.howItWorks} className="nav-link">
                So geht's
              </Link>
            </Nav.Item>
            <Nav.Item>
              <a href={Routes.shop.winePakets} className="nav-link">
                Weinprobepakete
              </a>
            </Nav.Item>
            <Nav.Item>
              <a href={Routes.shop.home} className="nav-link">
                Weine bestellen
              </a>
            </Nav.Item>
            <Nav.Item>
              <Link to={Routes.tasteFilter} className="nav-link">
                Weine entdecken
              </Link>
            </Nav.Item>
            {/* <Nav.Item>
              <a href={Routes.shop.coupons} className="nav-link">
                Gutscheine
              </a>
            </Nav.Item> */}
            <Nav.Item>
              <Link to={Routes.game} className="nav-link">
                Das Spiel
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={Routes.b2b} className="nav-link">
                B2B
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={Routes.aboutUs} className="nav-link">
                Über uns
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
