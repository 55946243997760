import React, { useState, useEffect } from "react";
import { Routes } from "../../Constants/Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faHome, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import GDPRCookies from "../../components/GDPRCookies/GDPRCookies";
import { Cookies } from "../../Constants/Cookies";
import { Link } from "react-router-dom";

export default function Footer() {
  const [isCookiesVisible, setIsCookiesVisible] = useState(false);

  useEffect(() => {
    const hasVisitedBeforeCookie = localStorage.getItem(Cookies.GDPR.isVisible);
    if (hasVisitedBeforeCookie === null) {
      setIsCookiesVisible(true);
      localStorage.setItem(Cookies.GDPR.isVisible, JSON.stringify(true));
    } else {
      setIsCookiesVisible(JSON.parse(hasVisitedBeforeCookie));
    }
  }, []);

  function toggleCookieSettings() {
    setIsCookiesVisible(true);
  }

  function handleCookieSubmit() {
    localStorage.setItem(Cookies.GDPR.isVisible, JSON.stringify(false));
    setIsCookiesVisible(false);
  }

  return (
    <>
      {isCookiesVisible ? (
        <>
          asd
          <GDPRCookies
            handleSubmit={handleCookieSubmit}
            setIsVisible={setIsCookiesVisible}
          />
        </>
      ) : (
        <></>
      )}
      <footer className="bg-dark">
        <div className="border-bottom border-secondary">
          <div className="container">
            <div className="row py-4 d-flex align-items-center">
              <div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
                <h6 className="mb-0">Folge uns in den Sozialen Medien!</h6>
              </div>
              <div className="col-md-6 col-lg-7 text-center text-md-right">
                <a
                  href={Routes.socialMedia.instagram}
                  className="m-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center text-md-left mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase font-weight-bold">Winzerliebe</h6>
              <hr
                className="mb-4 mt-0 d-inline-block bg-light"
                style={{ width: 60 }}
              />
              <p>Spielend neue Weine kennen lernen.</p>
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase font-weight-bold">Shop</h6>
              <hr
                className="mb-4 mt-0 d-inline-block bg-light"
                style={{ width: 60 }}
              />
              <p>
                <a
                  href={Routes.shop.winePakets}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Weinprobepakete
                </a>
              </p>
              <p>
                <a
                  href={Routes.shop.home}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Weine
                </a>
              </p>
              {/* <p>
                <Link to={Routes.shop.coupons}>Gutscheine</Link>
              </p> */}
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase font-weight-bold">Rechtliches</h6>
              <hr
                className="mb-4 mt-0 d-inline-block bg-light"
                style={{ width: 60 }}
              />
              <p>
                <Link to={Routes.imprint}>Impressum</Link>
              </p>
              <p>
                <Link to={Routes.privacyPolic}>Datenschutz</Link>
              </p>
              <p>
                <a href={Routes.shop.termsAndConditions}>AGB</a>
              </p>
              <div className={"cursor-pointer"} onClick={toggleCookieSettings}>
                Cookie-Einstellungen
              </div>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase font-weight-bold">Kontakt</h6>
              <hr
                className="mb-4 mt-0 d-inline-block bg-light"
                style={{ width: 60 }}
              />
              <p>
                <FontAwesomeIcon icon={faHome} className="text-dark" />{" "}
                Winzerliebe
                <br />
                <FontAwesomeIcon icon={faHome} /> Langendellschlag 17a
                <br />
                <FontAwesomeIcon icon={faHome} className="text-dark" /> 65199
                Wiesbaden
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} />
                <a href={Routes.mails.winzerliebe}>
                  {" "}
                  {Routes.mails.winzerliebe.replace("mailto:", "")}
                </a>
              </p>
              <a href={Routes.phones.winzerliebe}>
                <FontAwesomeIcon icon={faPhone} />{" "}
                {Routes.phones.winzerliebe.replace("tel:", "")}
              </a>
            </div>
          </div>
        </div>
        <p className="container footer-copyright py-3">
          Copyright © 2020 Winzerliebe. Alle Rechte vorbehalten.
        </p>
      </footer>{" "}
    </>
  );
}
