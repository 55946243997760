import React from "react";
import ImageWeinAnstoßenFrauenJpg from "../../assets/img/wein_anstoßen_frauen.jpg";
import ImageWeinAnstoßenFrauenWebp from "../../assets/img/wein_anstoßen_frauen.webp";
import WeinprobeLachenderMannJpg from "../../assets/img/weinprobe_lachender_mann.jpg";
import WeinprobeLachenderMannWebp from "../../assets/img/weinprobe_lachender_mann.webp";
import FrauAlleineTrinktWeinJpg from "../../assets/img/frau_alleine_trinkt_wein.jpg";
import FrauAlleineTrinktWeinWebp from "../../assets/img/frau_alleine_trinkt_wein.webp";
import "./Home.css";
import { Routes } from "../../Constants/Routes";
import { helmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";
import { useLocation } from "react-router-dom";

export default function Home() {
  const location = useLocation();
  const helmetProperties = helmetPropertiesFor(
    "Winzerliebe „Mission Lieblingswein“",
    location.pathname,
  );
  return (
    <div>
      <HelmetWrapper helmetProperties={helmetProperties} />
      <section className="jumbotron jumbotron-fluid jumbotronBackground mb-5">
        <div className="container">
          <h2 className="display-4 text-light">
            MAL WIEDER LUST AUF EINEN NEUEN?
          </h2>
          <p className="lead">
            ….und zwar auf deinen ganz persönlichen LIEBLINGSWEIN? Lade deine
            Freunde ein und entdeckt euren neuen Lieblingswein bei einer
            gamifizierten „BLIND-WEINVERKOSTUNG“ bei der ihr durch ein Spiel
            durchgeleitet werdet.
          </p>
          <a
            className="btn btn-primary btn-lg bg-winzer-red border-winzer-red"
            href={Routes.shop.winePakets}
            role="button"
          >
            Jetzt bestellen
          </a>
        </div>
      </section>
      <div>
        <section className="mb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-sm-12 pr-md-5 mb-3 mb-md-0">
                <picture>
                  <source
                    srcSet={ImageWeinAnstoßenFrauenWebp}
                    type="image/webp"
                  />
                  <source
                    srcSet={ImageWeinAnstoßenFrauenJpg}
                    type="image/jpeg"
                  />
                  <img
                    src={ImageWeinAnstoßenFrauenJpg}
                    className="img-fluid rounded shadow"
                    alt="Wein Anstoßen"
                  />
                </picture>
              </div>
              <div className="col-md-5 col-sm-12 pl-md-5 align-self-center">
                <h2>„Wein? Eigentlich lecker – aber welcher?</h2>
                <p>
                  Wenn es dir auch so geht, oder du einfach neue Weine mal
                  anders kennenlernen möchtest, bist du hier richtig: du lernst
                  Weine von "hidden Champion"-Winzern kennen während du sie
                  blind verkostest. Das geht so: die Weinetiketten sind
                  verdeckt, damit du davon nicht beeinflusst wirst. Quizfragen,
                  die bisher unbekannte kleine Stories über die Winzer verraten,
                  leiten durch die Verkostung. Du trinkst also nicht nur
                  leckeren Wein, sondern hast dazu Spiel, Spaß und Spannung
                  durch das Quiz-Spiel: erratet Stück für stück welche Stories
                  hinter den Weinen stecken und ordnet sie nachher richtig zu.
                  Weinwissen nicht nötig! Und mal ehrlich: dein Wein schmeckt
                  gleich anders, wenn du auch Stories dazu erzählen kannst.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-sm-12 pr-md-5 align-self-center">
                <h2>Eure LIEBLINGSWEIN-ENTDECKUNGSTOUR-CHALLENGE:</h2>
                <p>
                  Du kannst alleine oder mit Freunden - mit – oder gegeneinander
                  spielen. Aber Achtung: das ist eine Blindverkostung: Du kannst
                  dich nicht vom Etikett ablenken oder beeinflussen lassen. Du
                  konzentrierst dich nur auf deine Sinne - du schnuppert,
                  schmeckst und rätselst. Und am Ende kannst du das Etikett
                  aufdecken und sehen, wie dein neuer Lieblingswein aussieht.
                </p>
              </div>
              <div className="col-md-7 col-sm-12 pl-md-5 mb-3 mb-md-0">
                <picture>
                  <source
                    srcSet={WeinprobeLachenderMannWebp}
                    type="image/webp"
                  />
                  <source
                    srcSet={WeinprobeLachenderMannJpg}
                    type="image/jpeg"
                  />
                  <img
                    src={WeinprobeLachenderMannJpg}
                    className="img-fluid rounded shadow"
                    alt="Wein Anstoßen"
                  />
                </picture>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-sm-12 pr-md-5 mb-3 mb-md-0">
                <picture>
                  <source
                    srcSet={FrauAlleineTrinktWeinWebp}
                    type="image/webp"
                  />
                  <source srcSet={FrauAlleineTrinktWeinJpg} type="image/jpeg" />
                  <img
                    src={FrauAlleineTrinktWeinJpg}
                    className="img-fluid rounded shadow"
                    alt="Wein Anstoßen"
                  />
                </picture>
              </div>
              <div className="col-md-5 col-sm-12 pl-md-5 align-self-center">
                <h2>Sounds good, oder? So geht‘s:</h2>
                <p>
                  Entdecke „hidden champions Weine“ von mega tollen Winzern.
                </p>
                <ol>
                  <li>
                    Finde das richtige Weinprobenpaket für den phänomenalen
                    Abend.
                  </li>
                  <li>
                    Machs dir alleine gemütlich oder lade Freunde, Familie oder
                    Kollegen zu einem Weinproben-Abend ein.
                  </li>
                  <li>
                    Such dir deinen Lieblingsplatz für die Weinprobe – zu Hause,
                    am Strand oder im Park und beantworte die spannenden Fragen.
                  </li>
                  <li>
                    Die Lösungen führen zu der jeweils einzigartigen Story der
                    Weine.
                  </li>
                  <li>
                    „And the Winner is….“ – eigentlich gewinnen alle: denn
                    bestimmt findet jeder seinen Lieblingswein{" "}
                    <span role="img" aria-label="Smiling Emoji">
                      😊
                    </span>
                    .
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
