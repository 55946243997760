import { helmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";
import React from "react";
import { useLocation } from "react-router-dom";

export default function Game() {
  const location = useLocation();
  const helmetProperties = helmetPropertiesFor(
    "Winzerliebe „Mission Lieblingswein“",
    location.pathname,
  );
  return (
    <div>
      <HelmetWrapper helmetProperties={helmetProperties} />
      <section className="my-5">
        <div className="container">
          <h1>Das Spiel</h1>
          <section className="mb-5">
            <div className="container">
              <div className="row">
                <h2>„Let’s get ready to taste it!“</h2>
                <p>
                  Hand aufs Herz: weißt Du, welcher der blind verkosteten Weine
                  von einer uralten und erst kürzlich wieder entdeckten Weinrebe
                  stammen könnte? Und zu welchem Promi dieser tolle Rosé am
                  besten passen würde?Finde es heraus! Jedem Weinpaket liegen
                  Spielpläne bei – einer für jeden Mitspieler. Folgt der
                  Anleitung auf dem Spielplan. Jeder Spieler wird dadurch durch
                  die Weinprobe geführt und beantwortet Fragen. Riechen,
                  schmecken, pseudo-fachsimpeln, lachen – alles inbegriffen.
                  Natürlich sammelt man auch Punkte und am Ende gewinnt die
                  höchste Punktzahl. Jetzt könnt ihr zeigen, wieviel
                  Weinpassionista in euch steckt! – und bestimmt lernt ihr ganz
                  nebenbei euren neuen Lieblingswein kennen. Wer mehr wissen
                  will, scannt die QR-codes auf dem Spielplan ab.
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
