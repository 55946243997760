import React, { useState } from "react";
// @ts-ignore
import { GDPRSettings, GDPRConfigBuilder, cookiesTool } from "react-gdpr";
import { Cookies } from "../../Constants/Cookies";
import "./GDPRCookies.css";

interface Props {
  setIsVisible(value: boolean): void;
  handleSubmit(): void;
}

export default function GDPRSettingsPanel(props: Props): JSX.Element {
  const [render, setRender] = useState(0);

  function incRender() {
    setRender(render + 1);
  }

  function handleToggle(id: string, value: string) {
    cookiesTool.setActive(id, value);
    localStorage.setItem(id, value);
    incRender();
  }

  let configBuilder = new GDPRConfigBuilder();
  configBuilder
    .setLocale(
      "Datenschutzeinstellungen",
      "Diese Internetseite verwendet Cookies und Google Analytics für die Analyse und Statistik. Cookies helfen uns, die Benutzerfreundlichkeit unserer Website zu verbessern. Durch die weitere Nutzung der Website stimmen Sie der Verwendung zu.",
      "Bestätigen",
      "Aktiv",
      "Inaktiv",
      "Immer aktiv",
    )
    .addSetting(
      Cookies.GDPR.needed,
      "Benötigte Cookies",
      "Ohne diese Cookies funtioniert die Webseite nicht. Zum beispiel der Einkaufswagen oder die Anmeldung/Registrierung.",
      true,
      false,
    )
    .addSetting(
      Cookies.GDPR.statistics,
      "Statistiken",
      "Erlaubt die Erfassung von Analysen und Statistiken zu dieser Webseite.",
      cookiesTool.isActive(Cookies.GDPR.statistics),
      true,
    );
  let config = configBuilder.getConfig();

  return (
    <div className="gdpr-settings-panel">
      <GDPRSettings
        onValidate={props.handleSubmit}
        onToggle={handleToggle}
        {...config}
      />
    </div>
  );
}
