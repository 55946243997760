//Utils for react-helment meta data generation

import preval from "preval.macro";
import { HelmetProperties } from "helmet";

export const HelmetBaseUrl = "https://winzerliebe.de/";

// Creates a build date for the meta tags
const buildDate = preval`module.exports = new Date().toISOString();`;
const date = new Date(buildDate);
const formattedBuildDate =
  date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

function crawlValue(value: string): string {
  return false ? "noindex" : value;
}

export function baseHelmetPropertiesFor(
  language: string,
  keywords: string,
  description: string,
): HelmetProperties {
  return {
    titleTemplate: "%s",
    defaultTitle: "Winzerliebe „Mission Lieblingswein“",
    title: "Winzerliebe „Mission Lieblingswein“",
    charSet: "utf-8",
    lang: language,
    keywords: keywords,
    description: description,
    buildDate: formattedBuildDate,
    googleBot: crawlValue("all"),
    bingBot: crawlValue("all"),
    robots: crawlValue("all"),
    ogTitle: "winzerliebe.de",
    ogUrl: HelmetBaseUrl,
    ogDescription: description,
    ogType: "website",
    ogSiteName: "winzerliebe.de",
    ogImage: "https://winzerliebe.de/static/media/logo.da78f5ba.png",
    twitterTitle: "winzerliebe.de",
    twitterCard: "summary_large_image",
    twitterImage: "https://winzerliebe.de/static/media/logo.da78f5ba.png",
    canonical: HelmetBaseUrl,
  };
}

export function helmetPropertiesFor(
  title: string,
  location: string,
  keywords?: string,
  description?: string,
  audience?: string,
  abstract?: string,
): HelmetProperties {
  return {
    title: title,
    keywords: keywords,
    description:
      description ??
      "Winzerliebe „Mission Lieblingswein“. Blindverkostung zuhause.",
    buildDate: formattedBuildDate,
    audience: audience,
    abstract: abstract,
    ogTitle: title,
    ogUrl: HelmetBaseUrl + location,
    ogDescription:
      description ??
      "Winzerliebe „Mission Lieblingswein“. Blindverkostung zuhause.",
    twitterTitle: title,
    canonical: HelmetBaseUrl + location,
  };
}
