import React from "react";
import { Helmet } from "react-helmet";
import { HelmetProperties } from "helmet";

type Props = {
  helmetProperties: HelmetProperties;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defineProp = function (obj: any, key: string, value: any): void {
  const config = {
    value: value,
    writable: true,
    enumerable: true,
    configurable: true,
  };
  Object.defineProperty(obj, key, config);
};

export default function HelmetWrapper({
  helmetProperties,
}: Props): JSX.Element {
  const extraHelmetProperties = {};

  if (helmetProperties.titleTemplate) {
    defineProp(
      extraHelmetProperties,
      "titleTemplate",
      helmetProperties.titleTemplate,
    );
  }

  if (helmetProperties.defaultTitle) {
    defineProp(
      extraHelmetProperties,
      "defaultTitle",
      helmetProperties.defaultTitle,
    );
  }

  if (helmetProperties.lang) {
    defineProp(extraHelmetProperties, "htmlAttributes", {
      lang: helmetProperties.lang,
    });
  }

  return (
    <Helmet {...extraHelmetProperties}>
      {helmetProperties.title && <title>{helmetProperties.title}</title>}
      {helmetProperties.charSet && <meta charSet={helmetProperties.charSet} />}
      {helmetProperties.lang && (
        <meta name="lang" content={helmetProperties.lang} />
      )}
      {helmetProperties.keywords && (
        <meta name="keywords" content={helmetProperties.keywords} />
      )}
      {helmetProperties.description && (
        <meta name="description" content={helmetProperties.description} />
      )}
      {helmetProperties.buildDate && (
        <meta name="date" content={helmetProperties.buildDate} />
      )}
      {helmetProperties.googleBot && (
        <meta name="googlebot" content={helmetProperties.googleBot} />
      )}
      {helmetProperties.bingBot && (
        <meta name="bingbot" content={helmetProperties.bingBot} />
      )}
      {helmetProperties.robots && (
        <meta name="robots" content={helmetProperties.robots} />
      )}
      {helmetProperties.audience && (
        <meta name="audience" content={helmetProperties.audience} />
      )}
      {helmetProperties.abstract && (
        <meta name="abstract" content={helmetProperties.abstract} />
      )}
      {helmetProperties.lang && (
        <meta httpEquiv="content-language" content={helmetProperties.lang} />
      )}
      {helmetProperties.ogTitle && (
        <meta property="og:title" content={helmetProperties.ogTitle} />
      )}
      {helmetProperties.ogUrl && (
        <meta property="og:url" content={helmetProperties.ogUrl} />
      )}
      {helmetProperties.ogDescription && (
        <meta
          property="og:description"
          content={helmetProperties.ogDescription}
        />
      )}
      {helmetProperties.ogType && (
        <meta property="og:type" content={helmetProperties.ogType} />
      )}
      {helmetProperties.ogSiteName && (
        <meta property="og:site_name" content={helmetProperties.ogSiteName} />
      )}
      {helmetProperties.ogImage && (
        <meta property="og:image" content={helmetProperties.ogImage} />
      )}
      {helmetProperties.twitterTitle && (
        <meta
          property="twitter:title"
          content={helmetProperties.twitterTitle}
        />
      )}
      {helmetProperties.twitterSite && (
        <meta property="twitter:site" content={helmetProperties.twitterSite} />
      )}
      {helmetProperties.twitterCard && (
        <meta property="twitter:card" content={helmetProperties.twitterCard} />
      )}
      {helmetProperties.twitterCreator && (
        <meta
          property="twitter:creator"
          content={helmetProperties.twitterCreator}
        />
      )}
      {helmetProperties.twitterImage && (
        <meta
          property="twitter:image'"
          content={helmetProperties.twitterImage}
        />
      )}
      {helmetProperties.canonical && (
        <link rel="canonical" href={helmetProperties.canonical} />
      )}
    </Helmet>
  );
}
