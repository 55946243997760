import { helmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";
import React from "react";
import { useLocation } from "react-router-dom";

export default function PrivacyPolicy() {
  const location = useLocation();
  const helmetProperties = helmetPropertiesFor(
    "Winzerliebe „Mission Lieblingswein“",
    location.pathname,
  );
  return (
    <div className="my-5 text-break">
      <HelmetWrapper helmetProperties={helmetProperties} />
      <section className="container mb-5">
        <h1>Datenschutzerklärung</h1>
        <p>
          winzerliebe.de ist ein Angebot von Winzerliebe (Einzelunternehmens),
          vertreten durch den Geschäftsführer Andreas Herzog.
          <br />
          Nähere Angaben zu uns findest Du in unserem Impressum.
        </p>
      </section>
      <section className="container mb-5">
        <h2>1 Datenschutz auf einem Blick</h2>
        <h3>1.1 Allgemeine Hinweise</h3>
        <p>
          Wir nehmen den Schutz Deiner persönlichen Daten sehr ernst. Deshalb
          halten wir uns strikt an die Regeln der Datenschutzgesetze.
          Personenbezogene Daten werden auf dieser Webseite nur im technisch
          notwendigen Umfang erhoben. In keinem Fall werden die erhobenen Daten
          verkauft oder aus anderen Gründen an Dritte weitergegeben. Die
          nachfolgenden Hinweise geben Dir einen Überblick darüber, wie wir
          diesen Schutz gewährleisten und welche Art von Daten zu welchem Zweck
          erhoben werden.
        </p>
        <h3>1.2 Datenerfassung auf unserer Website</h3>
        <p>
          <b>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </b>
          <br />
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Die Kontaktdaten kannst Du dem Impressum dieser
          Website entnehmen.
          <br />
          <br />
          <b>Wie erfassen wir Deine Daten?</b>
          <br />
          Deine Daten werden zum einen dadurch erhoben, dass Du uns diese
          mitteilst. Hierbei kann es sich z.B. um Daten handeln, die Du in ein
          Kontaktformular eingibst. Andere Daten werden automatisch beim Besuch
          der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
          sobald Du unsere Website besuchst.
          <br />
          <br />
          <b>Wofür nutzen wir Deine Daten?</b>
          <br />
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Deines
          Nutzerverhaltens verwendet werden.
          <br />
          <br />
          <b>Welche Rechte hast Du bezüglich Deiner Daten?</b>
          <br />
          Du hast jederzeit das Recht unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Deiner gespeicherten personenbezogenen Daten zu
          erhalten. Du hast außerdem ein Recht, die Berichtigung, Sperrung oder
          Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen
          zum Thema Datenschutz kannst Du dich jederzeit unter der im Impressum
          angegebenen Adresse an uns wenden. Des Weiteren steht Dir ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        </p>
        <h3>1.3 Analyse-Tools und Tools von Drittanbietern</h3>
        <p>
          Beim Besuch unserer Website kann Dein Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit Cookies und mit
          sogenannten Analyseprogrammen. Die Analyse Deines Surf-Verhaltens
          erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Dir
          zurückverfolgt werden. Du kannst dieser Analyse widersprechen oder sie
          durch die Nichtbenutzung bestimmter Tools verhindern. Details hierzu
          entnimmst Du unserer Datenschutzerklärung unter der{" "}
          <b>Überschrift "3.8 Drittmodule und Analysetools"</b>. Du kannst
          dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten
          werden wir Dich in dieser Datenschutzerklärung informieren. 
        </p>
      </section>
      <section className="container mb-5">
        <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
        <h3>2.1 Datenschutz</h3>
        <p>
          Wir behandeln Deine personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung. Wenn Du diese Website benutzt, werden
          verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
          sind Daten, mit denen Du persönlich identifiziert werden kannst. Die
          vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
          und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
          das geschieht.
          <br />
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <h3>2.2 Hinweis zur verantwortlichen Stelle</h3>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <ul className="list-unstyled">
          <li>Winzerliebe (Einzelunternehmen)</li>
          <li>Andreas Herzog</li>
          <li>Langendellschlag 17a</li>
          <li>65199 Wiesbaden</li>
          <li>Deutschland</li>
        </ul>
        <ul className="list-unstyled">
          <li>Telefon: 0177 6111794</li>
          <li>
            E-Mail: <a href="mailto:info@winzerliebe.de">info@winzerliebe.de</a>
          </li>
        </ul>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <h3>2.3 Widerruf Deiner Einwilligung zur Datenverarbeitung</h3>
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Deiner ausdrücklichen
          Einwilligung möglich. Du kannst eine bereits erteilte Einwilligung
          jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
          an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <h3>2.4 Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
        <p>
          Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
          Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
          Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
          Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten
          sowie deren Kontaktdaten findest du hier:{" "}
          <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
        </p>
        <h3>2.5 Recht auf Datenübertragbarkeit</h3>
        <p>
          Du hast das Recht, Daten, die wir auf Grundlage Deiner Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an dich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Du die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangst, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <h3>2.6 SSL- bzw. TLS-Verschlüsselung</h3>
        <p>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Du an uns als Seitenbetreiber sendest, eine SSL-bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennst Du daran,
          dass die Adresszeile des Browsers von "http://" auf "https://"
          wechselt und an dem Schloss-Symbol in Deiner Browserzeile.
          <br />
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Du an uns übermittelst, nicht von Dritten mitgelesen
          werden.
        </p>
        <h3>2.7 Verschlüsselter Zahlungsverkehr auf dieser Website</h3>
        <p>
          Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
          Verpflichtung, uns Deine Zahlungsdaten (z.B. Kontonummer bei
          Einzugsermächtigung) zu übermitteln, werden diese Daten zur
          Zahlungsabwicklung benötigt. Der Zahlungsverkehr über die gängigen
          Zahlungsmittel (Visa/MasterCard, Lastschriftverfahren) erfolgt
          ausschließlich über eine verschlüsselte SSL- bzw. TLS Verbindung. Eine
          verschlüsselte Verbindung erkennst Du daran, dass die Adresszeile des
          Browsers von "http://" auf "https://" wechselt und an dem
          Schloss-Symbol in Deiner Browserzeile.
          <br />
          Bei verschlüsselter Kommunikation können Deine Zahlungsdaten, die Du
          an uns übermittelst, nicht von Dritten mitgelesen werden.
        </p>
        <h3>2.8 Auskunft, Sperrung, Löschung</h3>
        <p>
          Du hast im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Deine gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
          oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten kannst Du dich jederzeit unter der im Impressum
          angegebenen Adresse an uns wenden.
        </p>
      </section>
      <section className="container mb-5">
        <h2>3. Datenerfassung auf unserer Website</h2>
        <h3>3.1 Cookies</h3>
        <p>
          Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
          richten auf Deinem Rechner keinen Schaden an und enthalten keine
          Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
          effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
          die auf Deinem Rechner abgelegt werden und die Dein Browser speichert.
          Die meisten der von uns verwendeten Cookies sind so genannte
          "Session-Cookies". Sie werden nach Ende Deines Besuchs automatisch
          gelöscht. Andere Cookies bleiben auf Deinem Endgerät gespeichert bis
          Du diese löschst. Diese Cookies ermöglichen es uns, Deinen Browser
          beim nächsten Besuch wiederzuerkennen. Du kannst Deinen Browser so
          einstellen, dass Du über das Setzen von Cookies informiert wirst und
          Cookies nur im Einzelfall erlaubst, die Annahme von Cookies für
          bestimmte Fälle oder generell ausschließt sowie das automatische
          Löschen der Cookies beim Schließen des Browsers aktivierst. Bei der
          Deaktivierung von Cookies kann die Funktionalität dieser Website
          eingeschränkt sein. Cookies, die zur Durchführung des elektronischen
          Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Dir
          erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
          werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
          Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
          Cookies zur technisch fehlerfreien und optimierten Bereitstellung
          seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Deines
          Surfverhaltens) gespeichert werden, werden diese in dieser
          Datenschutzerklärung gesondert behandelt.
        </p>
        <h3>3.2 Server-Log-Dateien</h3>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Dein Browser automatisch an
          uns übermittelt. Dies sind:
        </p>
        <ul>
          <li>Browsertyp und Browserversion,</li>
          <li>Betriebssystem,</li>
          <li>Referrer,</li>
          <li>verwendetes URL,</li>
          <li>Datum und Uhrzeit der Serveranfrage,</li>
          <li>IP-Adresse</li>
        </ul>
        <p>
          Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen. Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1
          lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines
          Vertrags oder vorvertraglicher Maßnahmen gestattet.
        </p>
        <h3>3.3 Registrierung auf dieser Website</h3>
        <p>
          Du kannst dich auf unserer Website registrieren, um zusätzliche
          Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
          verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder
          Dienstes, für den Du dich registriert hast. Die bei der Registrierung
          abgefragten Pflichtangaben müssen vollständig angegeben werden.
          Anderenfalls werden wir die Registrierung ablehnen. Für wichtige
          Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen
          Änderungen nutzen wir die bei der Registrierung angegebene
          E-Mail-Adresse, um Dich auf diesem Wege zu informieren.
          <br />
          Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
          auf Grundlage Deiner Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Du
          kannst eine von Dir erteilte Einwilligung jederzeit widerrufen. Dazu
          reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
          der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          Die bei der Registrierung erfassten Daten werden von uns gespeichert,
          solange Du auf unserer Website registriert bist und werden
          anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben
          unberührt.
        </p>
        <h3>3.4 Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
        <p>
          Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit
          sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des
          Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt
          auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von
          Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
          gestattet.
          <br />
          Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten
          (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies
          erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu
          ermöglichen oder abzurechnen.
          <br />
          Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
          Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
          Aufbewahrungsfristen bleiben unberührt.
        </p>
        <h3>
          3.5 Datenübermittlung bei Vertragsschluss für Online-Shops, Händler
          und Warenversand
        </h3>
        <p>
          Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies
          im Rahmen der Vertragsabwicklung notwendig ist, etwa an die mit der
          Lieferung der Ware betrauten Unternehmen oder das mit der
          Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende
          Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Du der
          Übermittlung ausdrücklich zugestimmt hast. Eine Weitergabe Deiner
          Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der
          Werbung, erfolgt nicht.
          <br />
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO,
          der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
          vorvertraglicher Maßnahmen gestattet.
        </p>
        <h3>
          3.6 Administration, Finanzbuchhaltung, Büroorganisation,
          Kontaktverwaltung
        </h3>
        <p>
          Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie
          Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der
          gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten
          wir dieselben Daten, die wir im Rahmen der Erbringung unserer
          vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen sind
          Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der
          Verarbeitung sind Kunden, Interessenten, Geschäftspart- ner und
          Websitebesucher betroffen. Der Zweck und unser Interesse an der
          Verarbeitung liegt in der Administration, Finanzbuchhaltung,
          Büroorganisation, Archivierung von Daten, also Aufgaben die der
          Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung unserer
          Aufgaben und Erbringung unserer Leistungen dienen. Die Löschung der
          Daten im Hinblick auf vertragliche Leistungen und die vertragliche
          Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten
          genannten Angaben.
          <br />
          Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung,
          Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere
          Gebührenstellen und Zahlungsdienstleister.
          <br />
          Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen
          Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen
          Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese
          mehrheitlich unternehmensbezogenen Daten speichern wir grundsätzlich
          dauerhaft.
        </p>
        <h3>3.7 betriebswirtschaftliche Analysen und Marktforschung</h3>
        <p>
          Um unser Geschäft wirtschaftlich betreiben, Markttendenzen, Kunden-
          und Nutzerwünsche erkennen zu können, analysieren wir die uns
          vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen, etc.
          Wir verarbeiten dabei Bestandsdaten, Kommunikationsdaten,
          Vertragsdaten, Zahlungsdaten, Nutzungsdaten, Metadaten auf Grundlage
          des Art. 6 Abs. 1 lit. f. DSGVO, wobei zu den betroffenen Personen
          Kunden, Interessenten, Geschäftspartner, Besucher und Nutzer des
          Onlineangebotes gehören.
          <br />
          Die Analysen erfolgen zum Zweck betriebswirtschaftlicher Auswertungen,
          des Marketings und der Marktforschung. Dabei können wir die Profile
          der registrierten Nutzer mit Angaben, z.B. zu deren in Anspruch
          genommenen Leistungen, berücksichtigen. Die Analysen dienen uns zur
          Steigerung der Nutzerfreundlichkeit, der Optimierung unseres Angebotes
          und der Betriebswirtschaftlichkeit. Die Analysen dienen alleine uns
          und werden nicht extern offenbart, sofern es sich nicht um anonyme
          Analysen mit zusammengefassten Werten handelt. Sofern diese Analysen
          oder Profile personenbezogen sind, werden sie mit Kündigung der Nutzer
          gelöscht oder anonymisiert, sonst nach zwei Jahren ab Vertragsschluss.
          Im Übrigen werden die gesamtbetriebswirtschaftlichen Analysen und
          allgemeine Tendenzbestimmungen nach Möglichkeit anonym erstellt.
        </p>
        <h3>3.8 Drittmodule und Analysetools</h3>
        <b>Google Analytics</b>
        <br />
        <p>
          Diese Website nutzt Funktionen des Webanalysedienstes Google
          Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA ("Google").
          <br />
          Google Analytics verwendet so genannte "Cookies". Das sind
          Textdateien, die auf Deinem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website durch Dich ermöglichen. Die durch
          den Cookie erzeugten Informationen über Deine Benutzung dieser Website
          werden in der Regel an einen Server von Google in den USA übertragen
          und dort gespeichert.
          <br />
          Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von
          Art. 6 Abs. 1 lit. F DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
          Webangebot als auch seine Werbung zu optimieren.
        </p>
        <b>Browser Plugin</b>
        <br />
        <p>
          Du kannst die Speicherung der Google-Cookies durch eine entsprechende
          Einstellung Deiner Browser Software verhindern; wir weisen Dich jedoch
          darauf hin, dass Du in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich wirst nutzen können. Du
          kannst darüber hinaus die Erfassung der durch den Cookie erzeugten und
          auf Deine Nutzung der Website bezogenen Daten (inkl. Deiner
          IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
          verhindern, indem Du das unter dem folgenden Link verfügbare
          Browser-Plugin herunterladen und installieren kannst:
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
        </p>
        <b>Widerspruch gegen Datenerfassung</b>
        <br />
        <p>
          Du kannst die Erfassung Deiner Daten durch Google Analytics
          verhindern, indem Du auf folgenden Link klickst. Es wird ein
          Opt-Out-Cookie gesetzt, der die Erfassung Deiner Daten bei zukünftigen
          Besuchen dieser Website verhindert: Google Analytics deaktivieren.
          <br />
          Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
          findest Du in der Datenschutzerklärung von Google:
          <a href="https://support.google.com/analytics/answer/6004245?hl=de">
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
        </p>
        <b>Hosting</b>
        <br />
        <p>
          Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
          Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
          Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
          Datenbankdienste, Sicherheitsleistungen sowie technische
          Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
          Onlineangebotes einsetzen.
          <br />
          Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
          Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
          Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
          Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer
          effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes
          gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
          Auftragsverarbeitungsvertrag).
        </p>
        <b>Demografische Merkmale bei Google Analytics</b>
        <br />
        <p>
          Unsere Website nutzt die Funktion "demografische Merkmale" von Google
          Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu
          Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
          Daten stammen aus interessenbezogener Werbung von Google sowie aus
          Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten
          Person zugeordnet werden. Du kannst diese Funktion jederzeit über die
          Anzeigeneinstellungen in Deinem Google-Konto deaktivieren oder die
          Erfassung Deiner Daten durch Google Analytics wie im Punkt
          "Widerspruch gegen Datenerfassung" dargestellt generell untersagen.
        </p>
        <b>Datenverwendung bei Anmeldung zum E-Mail-Newsletter</b>
        <br />
        <p>
          Wenn Du dich zu unserem Newsletter anmeldest, verwenden wir die
          hierfür erforderlichen oder gesondert von Dir mitgeteilten Daten, um
          Dir regelmäßig unseren E-Mail-Newsletter entsprechend Deiner
          Einwilligung zuzusenden. Inhalt des Newsletters: Wir versenden
          Newsletter, E-Mails und weitere elektronische Benachrichtigungen mit
          werblichen Informationen (nachfolgend „Newsletter“) nur mit der
          Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern
          im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret
          umschrieben werden, sind sie für die Einwilligung der Nutzer
          maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu
          unseren Leistungen und uns.
          <br />
          Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
          Daten erfolgt ausschließlich auf Grundlage Deiner Einwilligung (Art. 6
          Abs. 1 lit. a DSGVO). Double-Opt-In und Protokollierung: Die Anmeldung
          zu unserem Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren.
          D.h. Du erhältst nach der Anmeldung eine E-Mail, in der Du um die
          Bestätigung Deiner Anmeldung gebeten wirst. Diese Bestätigung ist
          notwendig, damit sich niemand mit fremden E-Mailadressen anmelden
          kann. Die Anmeldungen zum Newsletter werden protokolliert, um den
          Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen
          zu können. Hierzu gehört die Speicherung des Anmelde- und des
          Bestätigungszeitpunkts, als auch der IP-Adresse. Die erteilte
          Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren
          Nutzung zum Versand des Newsletters kannst Du jederzeit widerrufen,
          etwa über den "Abbestellen"-Link im Newsletter. Die Rechtmäßigkeit der
          bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
          unberührt.
          <br />
          Die von Dir zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
          Daten werden von uns bis zu Deiner Austragung aus dem Newsletter
          gespeichert und nach der Abbestellung des Newsletters gelöscht. Daten,
          die zu anderen Zwecken bei uns gespeichert wurden (z.B.
          E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.
          Der Versand des Newsletters und die mit ihm verbundene Erfolgsmessung
          erfolgt auf Grundlage einer Einwilligung der Empfänger gem. Art. 6
          Abs. 1 lit. a, Art. 7 DSGVO i.V.m § 7 Abs. 2 Nr. 3 UWG bzw. auf
          Grundlage der gesetzlichen Erlaubnis gem. § 7 Abs. 3 UWG.
          <br />
          Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
          unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO. Unser
          Interesse richtet sich auf den Einsatz eines nutzerfreundlichen sowie
          sicheren Newslettersystems, das sowohl unseren geschäftlichen
          Interessen dient als auch den Erwartungen der Nutzer entspricht und
          uns ferner den Nachweis von Einwilligungen erlaubt.
        </p>
        <b>Datenverwendung für Postwerbung</b>
        <br />
        <p>
          Darüber hinaus behalten wir uns vor, Deinen Vor- und Nachnamen, Deine
          Postanschrift und - soweit wir diese zusätzlichen Angaben im Rahmen
          der Vertragsbeziehung von Dir erhalten haben - Deinen Titel,
          akademischen Grad, Dein Geburtsjahr und Deine Berufs-, Branchen- oder
          Geschäftsbezeichnung in zusammengefassten Listen zu speichern und für
          eigene Werbezwecke zu nutzen, z.B. zur Zusendung von interessanten
          Angeboten und Informationen zu unseren Produkten per Briefpost.
          <br />
          Die Verarbeitung der Daten erfolgt ausschließlich auf Grundlage Deiner
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung
          zur Speicherung der Daten, sowie deren Nutzung zum Versand der
          Briefpost kannst Du jederzeit widerrufen, etwa über eine Nachricht an
          die oben beschriebene Kontaktmöglichkeit. Die Rechtmäßigkeit der
          bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
          unberührt.
          <br />
          Die von Dir zum Zwecke des Briefpost-Bezugs bei uns hinterlegten Daten
          werden von uns bis zu Deiner Austragung aus der Liste gespeichert und
          nach der Abbestellung der Briefpost gelöscht.
        </p>
        <b>Onlinepräsenzen in Sozialen Medien</b>
        <br />
        <p>
          Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
          Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern
          kommunizieren und sie dort über unsere Leistungen informieren zu
          können. Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten
          die Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren
          jeweiligen Betreiber.
          <br />
          Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben,
          verarbeiten wir die Daten der Nutzer sofern diese mit uns innerhalb
          der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge
          auf unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden.
        </p>
        <b>YouTube</b>
        <br />
        <p>
          Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube.
          Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno,
          CA 94066, USA ("YouTube").
          <br />
          Wenn Du eine unserer mit einem YouTube-Plugin ausgestatteten Seiten
          besuchst, wird eine Verbindung zu den Servern von YouTube hergestellt.
          Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Du
          besucht hast.
          <br />
          Wenn Du in Deinem YouTube-Account eingeloggt bist, ermöglichst Du
          YouTube, Dein Surfverhalten direkt Deinem persönlichen Profil
          zuzuordnen. Dies kannst Du verhindern, indem Du dich aus Deinem
          YouTube-Account ausloggst.
          <br />
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
          <br />
          Weitere Informationen zum Umgang mit Nutzerdaten findest Du in der
          Datenschutzerklärung von YouTube unter:
          <a href="https://www.google.de/intl/de/policies/privacy/">
            https://www.google.de/intl/de/policies/privacy/
          </a>
        </p>
        <b>Instagram</b>
        <br />
        <p>
          Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des
          Dienstes Instagram, angeboten durch die Instagram Inc., 1601 Willow
          Road, Menlo Park, CA, 94025, USA, eingebunden werden. Hierzu können
          z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören,
          mit denen Nutzer ihr Gefallen betreffend die Inhalte kundtun, den
          Verfassern der Inhalte oder unsere Beiträge abonnieren können. Sofern
          die Nutzer Mitglieder der Plattform In- stagram sind, kann Instagram
          den Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der
          Nutzer zuordnen. Datenschutzerklärung von Instagram: 
          <a href="http://instagram.com/about/legal/privacy/">
            http://instagram.com/about/legal/privacy/
          </a>
          .
        </p>
        <b>Pinterest</b>
        <br />
        <p>
          Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des
          Dienstes Pinterest, angeboten durch die Pinterest Inc., 635 High
          Street, Palo Alto, CA, 94301, USA, eingebunden werden. Hierzu können
          z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören,
          mit denen Nutzer ihr Gefallen betreffend die Inhalte kundtun, den
          Verfassern der Inhalte oder unsere Beiträge abonnieren können. Sofern
          die Nutzer Mitglieder der Plattform Pinterest sind, kann Pinterest den
          Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der
          Nutzer zuordnen. Daten- schutzerklärung von Pinterest: 
          <a href="https://about.pinterest.com/de/privacy-policy">
            https://about.pinterest.com/de/privacy-policy
          </a>
          .
        </p>
        <b>Kommentare und Beiträge</b>
        <br />
        <p>
          Wenn Nutzer Kommentare oder sonstige Beiträge hinterlassen, können
          ihre IP-Adressen auf Grundlage unserer be- rechtigten Interessen im
          Sinne des Art. 6 Abs. 1 lit. f. DSGVO für 7 Tage gespeichert werden.
          Das erfolgt zu unserer Sicherheit, falls jemand in Kommentaren und
          Beiträgen widerrechtliche Inhalte hinterlässt (Beleidigungen, verbo-
          tene politische Propaganda, etc.). In diesem Fall können wir selbst
          für den Kommentar oder Beitrag belangt werden und sind daher an der
          Identität des Verfassers interessiert.
          <br />
          Des Weiteren behalten wir uns vor, auf Grundlage unserer berechtigten
          Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO, die Angaben der Nutzer
          zwecks Spamerkennung zu verarbeiten.Die im Rahmen der Kommentare und
          Beiträge angegebenen Daten, werden von uns bis zum Widerspruch der
          Nutzer dauerhaft gespeichert.
        </p>
        <b>Kontaktaufnahme</b>
        <br />
        <p>
          Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
          Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur
          Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1
          lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem
          Customer-Relationship-Management System („CRM System“) oder
          vergleichbarer Anfragenorganisation gespeichert werden.
          <br />
          Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind.
          Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die
          gesetzlichen Archivierungspflichten.
        </p>
        <b>Hosting</b>
        <br />
        <p>
          Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
          Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
          Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
          Datenbankdienste, Sicher- heitsleistungen sowie technische
          Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
          Onlineangebotes einsetzen.
          <br />
          Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
          Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
          Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
          Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer
          effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes
          gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
          Auftragsverarbeitungsvertrag).
        </p>
        <b>Zahlungsanbieter PayPal</b>
        <br />
        <p>
          Auf unserer Website bieten wir u.a. die Bezahlung via PayPal an.
          Anbieter dieses Zahlungsdienstes ist die PayPal (Europe) S.à.r.l. et
          Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg ("PayPal").
          <br />
          Wenn Du die Bezahlung via PayPal auswählst, werden die von Dir
          eingegebenen Zahlungsdaten an PayPal übermittelt.
          <br />
          Die Übermittlung Deiner Daten an PayPal erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. a DSGVO (Einwilligung) und Art. 6 Abs. 1 lit. b DSGVO
          (Verarbeitung zur Erfüllung eines Vertrags). Du hast die Möglichkeit,
          Deine Einwilligung zur Datenverarbeitung jederzeit zu widerrufen. Ein
          Widerruf wirkt sich auf die Wirksamkeit von in der Vergangenheit
          liegenden Datenverarbeitungsvorgängen nicht aus.
        </p>
        <b>Änderung dieser Datenschutzerklärung</b>
        <br />
        <p>
          Winzerliebe kann diese Datenschutzerklärung aktualisieren, wenn
          Services oder Produkte sich ändern oder dies aus rechtlichen Gründen
          erforderlich ist. Bei Änderungen wird Winzerliebe Dich per E-Mail
          informieren und an prominenter Stelle auf dem Onlineshop mit
          angemessener Vorfrist eine Mitteilung über die Änderung integrieren.
        </p>
      </section>
      <section className="container mb-5">
        <p>Stand: 18.12.2020</p>
      </section>
    </div>
  );
}
