import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home/Home";
import NoMatch from "../pages/NoMatch/NoMatch";
import NavigationBar from "./header/NavigationBar";
import HowItWorks from "../pages/HowItWorks/HowItWorks";
import Game from "../pages/Game/Game";
import Imprint from "../pages/Imprint/Imprint";
import AboutUs from "../pages/AboutUs/AboutUs";
import Footer from "./footer/Footer";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import B2B from "../pages/B2B/B2B";
import ReadyToTaste from "../pages/ReadyToTaste/ReadyToTaste";
import TasteFilter from "../pages/TasteFilter/TasteFilter";
import { WinePaketTypes } from "../Constants/WinePaketTypes";
import { Routes } from "../Constants/Routes";

export default function NavRoutes(): JSX.Element {
  return (
    <>
      <NavigationBar />
      <Switch>
        <Route exact path={Routes.home}>
          <Home />
        </Route>
        <Route path={Routes.howItWorks}>
          <HowItWorks />
        </Route>
        <Route path={Routes.game}>
          <Game />
        </Route>
        <Route path={Routes.b2b}>
          <B2B />
        </Route>
        <Route path={Routes.aboutUs}>
          <AboutUs />
        </Route>
        <Route path={Routes.imprint}>
          <Imprint />
        </Route>
        <Route path={Routes.privacyPolic}>
          <PrivacyPolicy />
        </Route>
        {WinePaketTypes.map((winePaket) => {
          const winePaketUrl = `${Routes.readyToTaste}/${winePaket.url}`;
          return (
            <Route path={winePaketUrl} key={winePaketUrl}>
              <ReadyToTaste
                winePaketName={winePaket.name}
                winePaketVideos={winePaket.videos}
              />
            </Route>
          );
        })}
        <Route path={Routes.tasteFilter}>
          <TasteFilter />
        </Route>
        <Route>
          <NoMatch />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}
