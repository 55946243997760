export const Routes = {
  home: "/",
  howItWorks: "/how-it-works",
  game: "/game",
  b2b: "/b2b",
  aboutUs: "/about-us",
  imprint: "/imprint",
  privacyPolic: "/privacy-policy",
  tasteFilter: "/taste-filter",
  readyToTaste: "/ready-to-taste",
  shop: {
    coupons:
      "https://winzerliebe.de/shop/index.php?route=product/category&path=63",
    home: "https://winzerliebe.de/shop/",
    winePakets: "https://winzerliebe.de/shop/weinprobepakete",
    contact: "https://winzerliebe.de/shop/index.php?route=information/contact",
    termsAndConditions:
      "https://winzerliebe.de/shop/index.php?route=information/information&information_id=5",
  },
  socialMedia: {
    instagram: "https://www.instagram.com/winzerliebe_lieblingswein/",
  },
  mails: {
    winzerliebe: "mailto:info@winzerliebe.de",
    daubit: "mailto:daubit@daubit.org",
  },
  phones: {
    winzerliebe: "tel:01776111794",
    daubit: "tel:+4961133549585",
  },
  fax: {
    daubit: "tel:+4961133549586",
  },
  websites: {
    daubit: "https://www.daubit.org",
  },
};
