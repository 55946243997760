import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Environment from "../../components/Environment";
import { Routes } from "../../Constants/Routes";
import { useLocation } from "react-router-dom";
import { helmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";
import uniq from "lodash/uniq";

class Category {
  id: number;
  name: string;
  image: string;
  parentId: number;
  isSelected: boolean;

  constructor(id: number, name: string, image: string, parentId: number) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.parentId = parentId;
    this.isSelected = false;
  }
}

class Product {
  id: number;
  name: string;
  description: string;
  categoryId: number[];
  price: string;
  href: string;
  thumbnail: string;

  constructor(
    id: number,
    name: string,
    description: string,
    categoryId: number[],
    price: string,
    href: string,
    thumbnail: string,
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.categoryId = categoryId;
    this.price = price;
    this.href = href;
    this.thumbnail = thumbnail;
  }
}

interface CategoryButtonProps {
  category: Category;
  toggleSelectedCategories: (category: Category) => void;
}
function CategoryButton(props: CategoryButtonProps): JSX.Element {
  return (
    <button
      type="button"
      className={`btn ${
        props.category.isSelected ? "btn-success" : "btn-dark"
      } m-2 col-12 col-md-3 col-lg-2`}
      onClick={() => {
        props.toggleSelectedCategories(props.category);
      }}
    >
      {props.category.name}
    </button>
  );
}

interface ProductCardProps {
  product: Product;
}
function ProductCard(props: ProductCardProps): JSX.Element {
  return (
    <div className="mb-5 d-flex align-items-stretch col-lg-3 col-md-4 col-12">
      <div className="card">
        <a href={props.product.href} target="_blank" rel="noopener noreferrer">
          <img
            src={props.product.thumbnail}
            className="card-img-top"
            alt="Bild vom Wein"
          />
        </a>
        <div className="card-body">
          <a
            href={props.product.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5 className="card-title text-winzer-red">{props.product.name}</h5>
          </a>
          <p className="card-text text-dark">{props.product.description}</p>
        </div>
        <div className="card-footer">
          <small className="text-muted">{props.product.price}</small>
        </div>
      </div>
    </div>
  );
}
interface filteredProductsProps {
  products: Product[];
  selectedCategories: Category[];
}
function FilteredProducts(props: filteredProductsProps): JSX.Element {
  const filteredProducts = props.products.filter((product) => {
    let equalCategories = 0;

    if (props.selectedCategories.length !== 0) {
      props.selectedCategories.forEach((category) => {
        product.categoryId.forEach((productCategory) => {
          if (productCategory === category.id) equalCategories += 1;
        });
      });

      return props.selectedCategories.length === equalCategories;
    } else {
      return false;
    }
  });

  if (filteredProducts.length !== 0) {
    return (
      <div style={{ position: "relative" }} className="mt-5">
        <div className="row">
          {filteredProducts.map((product) => (
            <ProductCard product={product} key={product.id + "_product"} />
          ))}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

interface selectedCategoriesProps {
  selectedCategories: Category[];
  removeFromSelectedCategories: (category: Category) => void;
}
function SelectedCategories(props: selectedCategoriesProps): JSX.Element {
  return (
    <div className="row col">
      {props.selectedCategories.length === 0 ? (
        <p>Noch keine Auswahl getroffen.</p>
      ) : (
        props.selectedCategories.map((element) => (
          <button
            type="button"
            className="btn btn-dark m-2 col-2"
            onClick={() => {
              props.removeFromSelectedCategories(element);
            }}
            key={element.name}
          >
            {element.name}
          </button>
        ))
      )}
    </div>
  );
}

export default function TasteFilter() {
  const [categoryColorChildren, setCategoryColorChildren] = useState<
    Category[]
  >([]);
  const [categoryCountryChildren, setCategoryCountryChildren] = useState<
    Category[]
  >([]);
  const [categoryRegionChildren, setCategoryRegionChildren] = useState<
    Category[]
  >([]);
  const [categoryReebsortChildren, setCategoryReebsortChildren] = useState<
    Category[]
  >([]);
  const [categoryTasteChildren, setCategoryTasteChildren] = useState<
    Category[]
  >([]);
  const [categoryTasteStyleChildren, setCategoryTasteStyleChildren] = useState<
    Category[]
  >([]);
  const [categoryTastesLikeChildren, setCategoryTastesLikeChildren] = useState<
    Category[]
  >([]);
  const [categoryTastesWithChildren, setCategoryTastesWithChildren] = useState<
    Category[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const helmetProperties = helmetPropertiesFor(
    "Winzerliebe „Mission Lieblingswein“",
    location.pathname,
  );

  const winzerliebeURL = useMemo(
    () =>
      Environment.isProduction
        ? Routes.shop.home
        : "http://localhost:80/index.php",
    [],
  );

  useEffect(() => {
    fetchParentCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchParentCategories() {
    const prdouctsRoute = "?route=api/products/categories";
    const apiURL = `${winzerliebeURL}${prdouctsRoute}`;

    axios
      .get(apiURL)
      .then((response) => {
        let parentCategories: Category[] = [];

        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i];
          const isNavigationElement = element["top"] === "1";

          if (isNavigationElement) continue;

          const category = new Category(
            +element["category_id"],
            element["name"],
            element["image"],
            +element["parent_id"],
          );

          parentCategories.push(category);
        }
        fetchChildrenCategories(parentCategories);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function fetchChildrenCategories(parentCategories: Category[]) {
    const prdouctsRoute = "?route=api/products/categories";

    parentCategories.forEach((parentCategory) => {
      const apiURL = `${winzerliebeURL}${prdouctsRoute}&parent_id=${parentCategory.id}`;

      axios
        .get(apiURL)
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i];

            const childCategory = new Category(
              +element["category_id"],
              element["name"],
              element["image"],
              +element["parent_id"],
            );

            if (
              childCategory.id === undefined ||
              childCategory.name === undefined ||
              childCategory.parentId === undefined
            )
              continue;

            if (childCategory.parentId === 69) {
              // Farbe
              setCategoryColorChildren((old) => [
                ...uniq([...old, childCategory]),
              ]);
            } else if (childCategory.parentId === 77) {
              // Herkunftsland
              setCategoryCountryChildren((old) => [
                ...uniq([...old, childCategory]),
              ]);
            } else if (childCategory.parentId === 79) {
              // Herkunftsregion
              setCategoryRegionChildren((old) => [
                ...uniq([...old, childCategory]),
              ]);
            } else if (childCategory.parentId === 84) {
              // Rebsorten
              setCategoryReebsortChildren((old) => [
                ...uniq([...old, childCategory]),
              ]);
            } else if (childCategory.parentId === 97) {
              // Geschmack
              setCategoryTasteChildren((old) => [
                ...uniq([...old, childCategory]),
              ]);
            } else if (childCategory.parentId === 102) {
              // Geschmacksstil
              setCategoryTasteStyleChildren((old) => [
                ...uniq([...old, childCategory]),
              ]);
            } else if (childCategory.parentId === 110) {
              // Schmeckt nach
              setCategoryTastesLikeChildren((old) => [
                ...uniq([...old, childCategory]),
              ]);
            } else if (childCategory.parentId === 135) {
              // Schmeckt mit
              setCategoryTastesWithChildren((old) => [
                ...uniq([...old, childCategory]),
              ]);
            } else {
              console.log("Couldn't save category:", childCategory);
            }
          }

          fetchProductsFromCategory();
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  function fetchProductsFromCategory() {
    const prdouctsRoute = "?route=api/products";
    const apiURL = `${winzerliebeURL}${prdouctsRoute}`;

    setProducts([]);

    axios
      .get(apiURL)
      .then((response) => {
        setProducts([]);
        for (let i = 0; i < response.data["products"].length; i++) {
          const element = response.data["products"][i];

          const elementCategoryIds = element["category_id"];
          const categoryIds: number[] = [];

          if (Array.isArray(elementCategoryIds)) {
            elementCategoryIds.forEach((category) => {
              categoryIds.push(+category);
            });
          } else {
            categoryIds.push(+elementCategoryIds);
          }

          const elementHref: string = element["href"];
          const href = elementHref.replace("&amp;", "&");

          const product = new Product(
            +element["product_id"],
            element["name"],
            element["description"],
            categoryIds,
            element["price"],
            href,
            element["thumb"],
          );

          setProducts((old) => [...old, product]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function toggleSelectedCategories(category: Category) {
    if (!selectedCategories.includes(category)) {
      category.isSelected = true;
      setSelectedCategories((old) => [...old, category]);
    } else {
      category.isSelected = false;
      setSelectedCategories((old) =>
        old.filter((item) => item.id !== category.id),
      );
    }
  }

  function removeFromSelectedCategories(category: Category) {
    category.isSelected = false;
    setSelectedCategories((old) =>
      old.filter((item) => item.id !== category.id),
    );
  }

  if (isLoading) {
    return (
      <div>
        <HelmetWrapper helmetProperties={helmetProperties} />
        <section className="my-5">
          <div className="container">
            <div className="mx-auto">
              <p className="text-center">
                <b>Lade...</b>
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div>
        <HelmetWrapper helmetProperties={helmetProperties} />
        <section className="my-5">
          <div className="container">
            <div className="row col">
              <h2>Mein Wein soll schmecken nach...</h2>
            </div>
            <div className="row col">
              <p>
                Ich kenne mich leider so gar nicht aus- echt peinlich am
                Weinregal.“ Geht‘s dir auch so oder so ähnlich? Dann bist du bei
                uns genau richtig – denn hier findest du genau den richtigen
                Wein für deinen Geschmack. Auch ganz ohne Vorwissen.
              </p>
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="row col">
              <h2>Farbe</h2>
            </div>
            <div className="row col">
              {categoryColorChildren.map((element) => (
                <CategoryButton
                  category={element}
                  key={`cat_${element.id}`}
                  toggleSelectedCategories={toggleSelectedCategories}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="row col">
              <h2>Herkunftsland</h2>
            </div>
            <div className="row col">
              {categoryCountryChildren.map((element) => (
                <CategoryButton
                  category={element}
                  key={`cat_${element.id}`}
                  toggleSelectedCategories={toggleSelectedCategories}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="row col">
              <h2>Herkunftsregion</h2>
            </div>
            <div className="row col">
              {categoryRegionChildren.map((element) => (
                <CategoryButton
                  category={element}
                  key={`cat_${element.id}`}
                  toggleSelectedCategories={toggleSelectedCategories}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="row col">
              <h2>Reebsorten</h2>
            </div>
            <div className="row col">
              {categoryReebsortChildren.map((element) => (
                <CategoryButton
                  category={element}
                  key={`cat_${element.id}`}
                  toggleSelectedCategories={toggleSelectedCategories}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="row col">
              <h2>Geschmack</h2>
            </div>
            <div className="row col">
              {categoryTasteChildren.map((element) => (
                <CategoryButton
                  category={element}
                  key={`cat_${element.id}`}
                  toggleSelectedCategories={toggleSelectedCategories}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="row col">
              <h2>Geschmacksstil</h2>
            </div>
            <div className="row col">
              {categoryTasteStyleChildren.map((element) => (
                <CategoryButton
                  category={element}
                  key={`cat_${element.id}`}
                  toggleSelectedCategories={toggleSelectedCategories}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="row col">
              <h2>Schmeckt Nach</h2>
            </div>
            <div className="row col">
              {categoryTastesLikeChildren.map((element) => (
                <CategoryButton
                  category={element}
                  key={`cat_${element.id}`}
                  toggleSelectedCategories={toggleSelectedCategories}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="row col">
              <h2>Schmeck Mit</h2>
            </div>
            <div className="row col">
              {categoryTastesWithChildren.map((element) => (
                <CategoryButton
                  category={element}
                  key={`cat_${element.id}`}
                  toggleSelectedCategories={toggleSelectedCategories}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="row col">
              <h2>Mein Wein soll schmecken nach:</h2>
            </div>
            <SelectedCategories
              selectedCategories={selectedCategories}
              removeFromSelectedCategories={removeFromSelectedCategories}
            />
            <FilteredProducts
              selectedCategories={selectedCategories}
              products={products}
            />
          </div>
        </section>
      </div>
    );
  }
}
