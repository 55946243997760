export const WinePaketTypes = [
  {
    name: "Urzeit Weine",
    url: "urzeit-weine",
    videos: [
      {
        name: "Wörners Schloss",
        url: "https://www.youtube-nocookie.com/embed/mnj8oNVWvTI",
      },
    ],
  },
  {
    name: "Rosarot und Kunterbund",
    url: "rosarot-und-kunterbund",
    videos: [
      {
        name: "Spätburgunder Rosé trocken (Schmetterling)",
        url: "https://www.youtube-nocookie.com/embed/-QVin8t5C2E",
      },
    ],
  },
  {
    name: "Patchwork Families",
    url: "patchwork-families",
    videos: [
      {
        name: "Weißer +  Grauer Burgunder trocken (Bär)",
        url: "https://www.youtube-nocookie.com/embed/wMYJugqNq_c",
      },
    ],
  },
  {
    name: "Promi's Lieblinge",
    url: "promis-lieblinge",
    videos: [],
  },
  {
    name: "Hüpfer vs Hasen",
    url: "hupfer-vs-hasen",
    videos: [
      {
        name: "Wörners Schloss",
        url: "https://www.youtube-nocookie.com/embed/mnj8oNVWvTI",
      },
    ],
  },
  {
    name: "Rote Domina gesucht",
    url: "rote-domina-geasucht",
    videos: [
      {
        name: "Wörners Schloss",
        url: "https://www.youtube-nocookie.com/embed/mnj8oNVWvTI",
      },
    ],
  },
  {
    name: "Erfinderisch Anders",
    url: "erfinderisch-anders",
    videos: [
      {
        name: "Wörners Schloss",
        url: "https://www.youtube-nocookie.com/embed/mnj8oNVWvTI",
      },
    ],
  },
  {
    name: "Störrische Weine: steil, steinig und alt",
    url: "storrische-weine-steil-steinig-alt",
    videos: [
      {
        name: "Wörners Schloss",
        url: "https://www.youtube-nocookie.com/embed/mnj8oNVWvTI",
      },
    ],
  },
  {
    name: "Superfood Reloaded: Fruchtbomben",
    url: "superfood-reloaded-fruchtbomben",
    videos: [],
  },
  {
    name: "Eine Sünde Wert",
    url: "eine-sunde-wert",
    videos: [
      {
        name: "Wörners Schloss",
        url: "https://www.youtube-nocookie.com/embed/mnj8oNVWvTI",
      },
    ],
  },
];
