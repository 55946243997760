import React, { useEffect, useState } from "react";
//@ts-ignore
import Analytics from "react-router-ga";
import NavRoutes from "./components/Routes";
import { Generics } from "./Constants/Generics";
import { BrowserRouter } from "react-router-dom";
import Environment from "./components/Environment";
import { Cookies } from "./Constants/Cookies";
import { baseHelmetPropertiesFor } from "HelmetUtils";
import HelmetWrapper from "HelmetWrapper";

function App() {
  const [isStatisticsCookieEnabled, setIsStatisticsCookieEnabled] =
    useState(false);
  const helmetProperties = baseHelmetPropertiesFor(
    "de",
    "winzer,winzerliebe,wein,lieblingswein,verkostung,blindverkostung,rotwein,weißwein,weinpaket",
    "Winzerliebe „Mission Lieblingswein“. Blindverkostung zuhause.",
  );

  useEffect(() => {
    const statisticsCookie = localStorage.getItem(Cookies.GDPR.statistics);
    if (statisticsCookie) {
      setIsStatisticsCookieEnabled(JSON.parse(statisticsCookie));
    }
  }, []);

  return (
    <React.Fragment>
      <HelmetWrapper helmetProperties={helmetProperties} />
      <BrowserRouter>
        {Environment.isProduction && isStatisticsCookieEnabled ? (
          <Analytics id={Generics.analyticsKey} debug>
            <NavRoutes />
          </Analytics>
        ) : (
          <NavRoutes />
        )}
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
